import React, { Component } from 'react';
import { connect } from 'react-redux';
import firebase from '../../services/firebase';
import './dashboard.css';
import loader from '../../assets/imgs/loader.gif';
import { Table, Popconfirm, Tabs, Input, Row, Col } from 'antd';
import 'antd/dist/antd.css';
import { withRouter } from 'react-router-dom';
import { BiPlus } from 'react-icons/bi';
import moment from 'moment';
import { addActivityLogs, isEmpty } from '../../utils/activity-logs';
import { checkDeletePermissions, checkWritePermissions } from '../../utils/native';

const { TabPane } = Tabs;
const { Search } = Input;
class Dashboard extends Component {
	constructor (props) {
		super(props);
		this.state = {
			isPermitted: checkWritePermissions(props, 'rigs'),
			isDeletePermitted: checkDeletePermissions(props, 'rigs'),
			uid: null,
			rigs: [],
			isAdmin: localStorage.getItem('admin') === 'true' ? true : false,
			Loader: false,
			selectedTab: 1,
			searchInput: '',
			activeRigs: [],
			scheduledRigs: [],
			completedRigs: [],
			canceledRigs: [],
			archivedRigs: [],
			users: props.auth.usersList
		};
	}

	componentDidMount () {
		const uid = localStorage.getItem('user');
		this.setState({ Loader: true });
		this.setState({ uid: uid });
		this.getRigs();
	}

	componentDidUpdate (prevProps) {
		if (prevProps.auth.subscribeStart === false && this.props.auth.subscribeStart === true) {
			this.setState({ isPermitted: checkWritePermissions(this.props, 'rigs'), isDeletePermitted: checkDeletePermissions(this.props, 'rigs') });
		}
		const { usersList } = this.props.auth;
		if (prevProps.auth.usersList.length !== usersList.length) {
			this.setState({ users: usersList });
		}
		const { rigsList } = this.props.rig;
		if (prevProps.rig.rigsList.length !== rigsList.length) {
			this.getRigs();
		}
	}

	getRigs () {
		const { rigsList } = this.props.rig;
		const scheduled = [];
		const completed = [];
		const active = [];
		const canceled = [];
		const archived = [];
		const rigs = [];
		for (const item of rigsList) {
			this.getUserDetails(item);
			if (!item.archive) {
				rigs.push(item);
				if (item.status === 'Scheduled') {
					scheduled.push(item);
				} else if (item.status === 'Completed') {
					completed.push(item);
				} else if (item.status === 'Active') {
					active.push(item);
				} else if (item.status === 'Canceled') {
					canceled.push(item);
				}
			} else {
				archived.push(item);
			}
		}
		this.setState({ rigs, activeRigs: active, scheduledRigs: scheduled, completedRigs: completed, canceledRigs: canceled, archivedRigs: archived });
		this.setState({ Loader: false });
	}

	getUserDetails (data) {
		const { users } = this.state;
		const userIndex = users.map(user => user.uid).indexOf(data.uid);
		const userIndex1 = users.map(user => user.uid).indexOf(data.updatedBy);
		if (userIndex !== -1) {
			data.createdBy = users[userIndex].firstName + ' ' + users[userIndex].lastName;
		}
		if (userIndex1 !== -1) {
			data.updatedByUser = users[userIndex1].firstName + ' ' + users[userIndex1].lastName;
		}
	}

	viewRig (key) {
		this.props.history.push(`/rigs/${key}`);
	}

	handleUpdate (key) {
		this.props.history.push(`/rigs/edit/${key}`);
	}

	async handleAction (rig, action) {
		const db = firebase.firestore();
		const reportsRef = db.collection('rigReports').where('rigId', '==', rig.$key);
		reportsRef.get().then((querySnapshot) => {
			querySnapshot.forEach(async (doc) => {
				if (doc.exists) {
					const data = doc.data();
					await db.collection('rigReports').doc(doc.id).update({...data, $key: doc.id, archive: action === 'delete'});
				}
			});
		});
		const jsaRef = db.collection('jobsafetyanalysis').where('rigId', '==', rig.$key);
		jsaRef.get().then((querySnapshot) => {
			querySnapshot.forEach(async (doc) => {
				if (doc.exists) {
					const data = doc.data();
					await db.collection('jobsafetyanalysis').doc(doc.id).update({...data, $key: doc.id, archive: action === 'delete'});
				}
			});
		});
		const rigCompaniesRef = db.collection('rigCompanies').where('rigId', '==', rig.$key);
		rigCompaniesRef.get().then((querySnapshot) => {
			querySnapshot.forEach(async (doc) => {
				if (doc.exists) {
					const data = doc.data();
					await db.collection('rigCompanies').doc(doc.id).update({...data, $key: doc.id, archive: action === 'delete'});
				}
			});
		});
		const rigDownRef = db.collection('Rig-Down').where('rigId', '==', rig.$key);
		rigDownRef.get().then((querySnapshot) => {
			querySnapshot.forEach(async (doc) => {
				if (doc.exists) {
					const data = doc.data();
					await db.collection('Rig-Down').doc(doc.id).update({...data, $key: doc.id, archive: action === 'delete'});
				}
			});
		});
		const summaryRef = db.collection('Rig-Summary').where('rigId', '==', rig.$key);
		summaryRef.get().then((querySnapshot) => {
			querySnapshot.forEach(async (doc) => {
				if (doc.exists) {
					const data = doc.data();
					await db.collection('Rig-Summary').doc(doc.id).update({...data, $key: doc.id, archive: action === 'delete'});
				}
			});
		});
		const rigUpRef = db.collection('Rig-Up').where('rigId', '==', rig.$key);
		rigUpRef.get().then((querySnapshot) => {
			querySnapshot.forEach(async (doc) => {
				if (doc.exists) {
					const data = doc.data();
					await db.collection('Rig-Up').doc(doc.id).update({...data, $key: doc.id, archive: action === 'delete'});
				}
			});
		});
		const assetsRef = db.collection('assets').where('rigId', '==', rig.$key);
		assetsRef.get().then((querySnapshot) => {
			querySnapshot.forEach(async (doc) => {
				if (doc.exists) {
					const data = doc.data();
					await db.collection('assets').doc(doc.id).update({...data, $key: doc.id, archive: action === 'delete'});
				}
			});
		});
		const rigpicsRef = db.collection('rigpics').where('rigId', '==', rig.$key);
		rigpicsRef.get().then((querySnapshot) => {
			querySnapshot.forEach(async (doc) => {
				if (doc.exists) {
					const data = doc.data();
					await db.collection('rigpics').doc(doc.id).update({...data, $key: doc.id, archive: action === 'delete'});
				}
			});
		});
		const drillingRigRef = db.collection('drilling-rig').where('rigId', '==', rig.$key);
		drillingRigRef.get().then((querySnapshot) => {
			querySnapshot.forEach(async (doc) => {
				if (doc.exists) {
					const data = doc.data();
					await db.collection('drilling-rig').doc(doc.id).update({...data, $key: doc.id, archive: action === 'delete'});
				}
			});
		});
		await addActivityLogs(rig, action, 'rigs', rig.$key);
		action === 'delete' ? rig.archive = true : rig.archive = false;
		await db.collection('rigs').doc(rig.$key).update(rig);
		this.getRigs();
		setTimeout(() => {
			alert(`Rig is ${action === 'delete' ? 'deleted successfully' : 'unarchived successfully'}`);
		}, 1000);
	}

	onChange = (key) => {
		this.setState({ selectedTab: parseInt(key), searchInput: '' });
	};

	rigsHeader = () => {
		const { searchInput, isPermitted } = this.state;
		return (
			<Row className="search-rig-header">
				<Col span={4}>
					{isPermitted &&
						<button onClick={() => this.props.history.push('/rigs/add')} className="site-btn rounded">
							<BiPlus size={40} onClick={() => this.props.history.push('/rigs/add')} />
						</button>
					}
				</Col>
				<Col span={20} className="search-btn-part">
					<Search
						placeholder="Search Name, Number, Job Number"
						onSearch={value => this.rigSearch(value)}
						allowClear={true}
						onChange={data => this.rigSearch(data.target.value)}
						value={searchInput}
					/>
					<button className="site-btn export-btn">Export</button>
				</Col>
			</Row>

		);
	}

	filterData = (list) => {
		const { searchInput } = this.state;
		const string = searchInput.toLowerCase();
		return list.filter((item) => item.name.toLowerCase().match(string) || item.number.toLowerCase().match(string) || item.jobNumber.toLowerCase().match(string));
	};

	rigsLists = (rigs) => {
		const { Column } = Table;
		const { isAdmin, selectedTab, isPermitted, isDeletePermitted } = this.state;
		return (
			<Table dataSource={this.filterData(rigs)} rowKey={(record, index) => record.uid + index} pagination={{ defaultPageSize: 10, showSizeChanger: false }}>
				<Column title="Name" dataIndex="name" key="name" />
				<Column title="Number" dataIndex="number" key="number" />
				<Column title="Job Number" dataIndex="jobNumber" key="jobNumber" />
				<Column title="Status" dataIndex="status" render={(_, record) => <p>{record.status !== 'Canceled' ? record.status : 'Cancelled'}</p>} />
				<Column title="Rig Down%" dataIndex="totalRigDown" className='totalRigDown' render={(_, record) => <p>{!isEmpty(record.totalRigDown) ? record.totalRigDown : '0%'}</p>} />
				<Column title="Rig Up%" dataIndex="totalRigUp" className='totalRigUp' render={(_, record) => <p>{!isEmpty(record.totalRigUp) ? record.totalRigUp : '0%'}</p>} />
				<Column title="Loadcount" dataIndex="loadcount" render={(_, record) => <p>{record.counters ? (record.counters.rigDown.nonPermits + record.counters.rigUp.nonPermits + record.counters.rigDown.permits + record.counters.rigUp.permits) : (record.loadcount ? record.loadcount : 0)}</p>} />
				<Column title="Release Date" dataIndex="releaseDate" render={(_, record) => <p>{record.releaseDate && moment(record.releaseDate).format('MMM DD, YYYY')}</p>} />
				<Column title="Move Type" dataIndex="moveType" key="moveType" />
				<Column title="Created By" dataIndex="createdBy" key="createdBy" />
				<Column title="Updated By" dataIndex="updatedByUser" key="updatedByUser" />
				<Column title="View" dataIndex="View" render={(_, record) =>
					this.state.rigs.length >= 1 ? (
						<p className="table-action-link box-link" onClick={() => this.viewRig(record.$key)}>View</p>
					) : null}
				/>
				{isPermitted &&
					<Column title="Edit" dataIndex="Edit" render={(_, record) =>
						this.state.rigs.length >= 1 ? (
							<Popconfirm overlayClassName="custom-popconfirm" title="Are you sure to update this rig?" onConfirm={() => this.handleUpdate(record.$key)}>
								<a href="true">Edit</a>
							</Popconfirm>
						) : null}
					/>
				}
				{isAdmin === true && selectedTab === 5 && isDeletePermitted &&
					<Column title="Unarchive" className="delete" dataIndex="Unarchive" render={(_, record) =>
						this.state.rigs.length >= 1 ? (
							<Popconfirm overlayClassName="custom-popconfirm" title="Are you sure to unarchive this rig ?" onConfirm={() => this.handleAction(record, 'unarchive')}>
								<a href="true">Unarchive</a>
							</Popconfirm>
						) : null}
					/>
				}
				{isAdmin === true && selectedTab !== 5 && isDeletePermitted &&
					<Column title="Delete" className="delete" dataIndex="Delete" render={(_, record) =>
						this.state.rigs.length >= 1 ? (
							<Popconfirm overlayClassName="custom-popconfirm" title="Are you sure to delete this rig ?" onConfirm={() => this.handleAction(record, 'delete')}>
								<a href="true">Delete</a>
							</Popconfirm>
						) : null}
					/>
				}
			</Table>
		);

	}

	rigSearch (value) {
		this.setState({ searchInput: value, loaded: true });
	}

	render () {
		const { uid, rigs, Loader, selectedTab, activeRigs, scheduledRigs, completedRigs, canceledRigs, archivedRigs } = this.state;

		if (uid === null || Loader === true) {
			return (
				<div className="loader">
					<img src={loader} alt="loader" />
				</div>
			);
		}

		return (
			<div className="card-tab-body">
				<div className="custom-tabs no-mt">
					<Tabs type="card" defaultActiveKey="1" onChange={this.onChange}>
						<TabPane tab={`Active (${activeRigs.length})`} key="1">
							{this.rigsHeader()}
							{activeRigs && selectedTab === 1 && this.rigsLists(activeRigs)}
						</TabPane>
						<TabPane tab={`Scheduled (${scheduledRigs.length})`} key="2">
							{this.rigsHeader()}
							{selectedTab === 2 && this.rigsLists(scheduledRigs)}
						</TabPane>
						<TabPane tab={`Completed (${completedRigs.length})`} key="3">
							{this.rigsHeader()}
							{selectedTab === 3 && this.rigsLists(completedRigs)}
						</TabPane>
						<TabPane tab={`Cancelled (${canceledRigs.length})`} key="4">
							{this.rigsHeader()}
							{selectedTab === 4 && this.rigsLists(canceledRigs)}
						</TabPane>
						<TabPane tab={`Archived (${archivedRigs.length})`} key="5">
							{this.rigsHeader()}
							{selectedTab === 5 && this.rigsLists(archivedRigs)}
						</TabPane>
						<TabPane tab={`ALL (${rigs.length})`} key="6">
							{this.rigsHeader()}
							{selectedTab === 6 && this.rigsLists(rigs)}
						</TabPane>
					</Tabs>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.authReducer,
	rig: state.rigReducer
});

export default connect(mapStateToProps)(withRouter(Dashboard));