import React, { Component } from 'react';
import { Slider, InputNumber } from 'antd';
import './mobile-preview.css';

class MobileNumbersSlider extends Component {
	constructor (props) {
		super(props);
		this.state = {
			inputValue: 1,
		};
	}

	onChange = value => {
		this.setState({
			inputValue: value,
		});
	};

	render () {
		const { inputValue } = this.state;
		const { details } = this.props;
		return (
			<>
				<div className="mobile-field">
					<div className="m-box-header">
						<p className="title">
							{details.required &&
								<span className="require-symbol">*</span>
							}
							{details.label}
							<span className="subtitle">{details.description}</span>
						</p>
					</div>
					<div className="m-box-content">
						<div className="m-rangslider">
							<Slider
								className="custom-range-slider"
								min={details.startLabel}
								max={details.endLabel}
								onChange={this.onChange}
								value={typeof inputValue === 'number' ? inputValue : 0}
							/>
							<InputNumber
								className="slider-count"
								min={details.startLabel}
								max={details.endLabel}
								value={inputValue}
								onChange={this.onChange}
							/>
						</div>
					</div>
				</div>
			</>
		);
	}
}
export default MobileNumbersSlider;
