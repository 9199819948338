import {
	LOG_IN_START,
	LOG_IN_SUCCESS,
	LOG_OUT,
	USER_SUBSCRIBE_START,
	USER_SUBSCRIBE_SUCCESS,
	USERS_LIST,
} from '../actions/authActions';

const initialState = {
	isLoggedIn: false,
	currentUser: null,
	subscribeStart: null,
	usersList: []
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOG_IN_START:
			return { ...state, isLoggedIn: false };
		case LOG_IN_SUCCESS:
			return { ...state, currentUser: action.data, isLoggedIn: true };
		case LOG_OUT:
			return { ...initialState };
		case USER_SUBSCRIBE_START:
			return { ...state, subscribeStart: false };
		case USER_SUBSCRIBE_SUCCESS:
			return { ...state, subscribeStart: true };
		case USERS_LIST:
			return { ...state, usersList: action.data };
		default:
			return state;
	}
};

export default authReducer;
