import React, { Component } from 'react';
import { Icon } from 'antd';
import './mobile-preview.css';

class MobileFileUpload extends Component {
	constructor (props) {
		super(props);
		this.state = {
		};
	}

	render () {
		const { details } = this.props;
		return (
			<>
				<div className="mobile-field">
					<div className="m-box-header">
						<p className="title">
							{details.label}
							<span className="subtitle">{details.description}</span>
						</p>
					</div>
					<div className="m-box-content">
						<button className="add-img-btn">
							<Icon type="upload" />
							Attach a file
						</button>
					</div>
				</div>
			</>
		);
	}
}
export default MobileFileUpload;
