import React, { Component } from 'react';
import { connect } from 'react-redux';
import firebase from '../../services/firebase';
import Moment from 'react-moment';
import rec from '../../assets/imgs/rec.png';
import { withRouter } from 'react-router-dom';
import { checkWritePermissions } from '../../utils/native';
import * as rigActions from '../../redux/actions/rigActions';

class AssetsList extends Component {
	constructor (props) {
		super(props);
		this.state = {
			isPermitted: checkWritePermissions(this.props, 'assets')
		};
	}

	componentDidUpdate (prevProps) {
		if (prevProps.auth.subscribeStart === false && this.props.auth.subscribeStart === true) {
			this.setState({ isPermitted: checkWritePermissions(this.props, 'assets') });
		}
	}

	componentDidMount () {
		const { assetsPicsList } = this.props.rig;
		if (assetsPicsList.length === 0) {
			this.getAssetsData();
		}
	}

	getAssetsData () {
		const db = firebase.firestore();
		const picsRef = db.collection('assets').orderBy('createdAt', 'desc');
		picsRef.get().then((querySnapshot) => {
			const temp = [];
			querySnapshot.forEach((doc) => {
				if (doc.exists) {
					const data = doc.data();
					data.$key = doc.id;
					if (data.archive !== true) {
						temp.push(data);
					}
				}
			});
			this.props.dispatchAssetsPicsList(temp);
		});
	}

	render () {
		const { isPermitted } = this.state;
		const { assetsPicsList } = this.props.rig;
		return (
			<div>
				{assetsPicsList.length > 0 &&
					<div className="rigImage-part">
						<div className="rigImage-row" id="rig_pics">
							{assetsPicsList.map((picture, index) => {
								return (
									<div className="rigImage-box" key={index}>
										<div className="inner-area" onClick={() => isPermitted && this.props.history.replace(`/assets/${picture.$key}`)}>
											{picture.url ?
												<img src={picture.url} alt="assest" className="rig-photo" />
												:
												<img src={rec} onLoad={this.onLoad} alt="no_rigpics" className="rig-photo" />
											}
											<h4><Moment format="MMM DD, YYYY">{picture.createdAt}</Moment></h4>
											<p className='picSection-time'><Moment format="h:mm a">{picture.createdAt}</Moment></p>
										</div>
									</div>
								);
							})}
						</div>
					</div>
				}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.authReducer,
	rig: state.rigReducer
});

const mapDispatchToProps = {
	dispatchAssetsPicsList: (data) => rigActions.assetsPicsList(data)
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AssetsList));