
import firebase from './firebase';

// Subcollection queries

export const addQuery = (col1, docId, col2, details) => {
	return new Promise(async (resolve, reject) => {
		const db = firebase.firestore();
		await db.collection(col1).doc(docId).collection(col2).add(details)
			.then((res) => {
				details.$key = res.id;
				resolve({ err: false, details});
			})
			.catch((err) => {
				alert(`Error! ${err.message}`);
				reject({err: true});
			});
	});
};

export const updateQuery = async (col1, docId, col2, details) => {
	const db = firebase.firestore();
	await db.collection(col1).doc(docId).collection(col2).doc(details.$key).update(details)
		.catch((err) => {
			new Error(err);
		});
};