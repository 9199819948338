import React, { Component } from 'react';
import { Rate } from 'antd';
import './mobile-preview.css';

class MobileOpenEnded extends Component {
	constructor (props) {
		super(props);
		this.state = {
		};
	}

	render () {
		const { details } = this.props;
		return (
			<>
				<div className="mobile-field">
					<div className="m-box-header">
						<p className="title">
							{details.required &&
								<span className="require-symbol">*</span>
							}
							{details.label}
							<span className="subtitle">{details.description}</span>
						</p>
					</div>
					<div className="m-box-content">
						{/* <div className="m-star-group">
							<Icon type="star" theme="filled" />
							<Icon type="star" theme="filled" />
							<Icon type="star" theme="filled" />
							<Icon type="star" />
							<Icon type="star" />
						</div> */}
						<Rate count={details.rateRange} />
						<div className="m-star-rating-text">
							<span>Very Bad</span>
							<span>Very Good</span>
						</div>
					</div>
				</div>
			</>
		);
	}
}
export default MobileOpenEnded;
