
import React, { Component } from 'react';
import { connect } from 'react-redux';
import firebase from '../../services/firebase';
import loader from '../../assets/imgs/loader.gif';
import { Table, Popconfirm, Tabs, Input, Row, Col, Modal, Form, Menu } from 'antd';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { IoIosAddCircleOutline } from 'react-icons/io';
import { changePassword, sendEmail } from '../../services/auth';
import { BsLayoutThreeColumns } from 'react-icons/bs';
import './users.css';
import { isEmpty } from '../../utils/activity-logs';
import { checkDeletePermissions, checkWritePermissions } from '../../utils/native';

const { TabPane } = Tabs;
const { Search } = Input;
class Users extends Component {
	constructor (props) {
		super(props);
		this.state = {
			isPermitted: checkWritePermissions(this.props, 'users', 'write'),
			isDeletePermitted: checkDeletePermissions(this.props, 'users', 'delete'),
			Loader: false,
			losEmployees: [],
			isAdmin: localStorage.getItem('admin') === 'true' ? true : false,
			isOwner: localStorage.getItem('owner') === 'true' ? true : false,
			admins: [],
			owners: [],
			users: [],
			selectedTab: 1,
			searchInput: '',
			showModal: false,
			validateTrigger: false,
			selectedUID: '',
			deleteRequestUsers: [],
			userColumns: [
				{
					title: 'First Name',
					dataIndex: 'firstName',
					key: 'firstName'
				},
				{
					title: 'Last Name',
					dataIndex: 'lastName',
					key: 'lastName'
				},
				{
					title: 'Email',
					dataIndex: 'email',
					key: 'email'
				},
				{
					title: 'Phone Number',
					dataIndex: 'phoneNumber',
					key: 'phoneNumber'
				},
				{
					title: 'User Type',
					dataIndex: 'userType',
					key: 'userType'
				},
				{
					title: 'Created At',
					dataIndex: 'createdAt',
					key: 'createdAt',
					render: (createdAt) => (createdAt && moment(createdAt).format('MMM DD, YYYY'))
				},
				{
					title: 'Updated At',
					dataIndex: 'updatedAt',
					key: 'updatedAt',
					render: (_, record) => (record.updatedAt ? moment(record.updatedAt).format('MMM DD, YYYY') : moment(record.createdAt).format('MMM DD, YYYY'))
				},
				{
					title: 'Verified',
					dataIndex: 'verified',
					key: 'verified'
				},
				{
					title: 'Country Code',
					dataIndex: 'countryCode',
					key: 'countryCode'
				},
				{
					title: 'Employee ID',
					dataIndex: 'employeeID',
					key: 'employeeID'
				},
				{
					title: 'Shop/Office/Field',
					dataIndex: 'selectedShop',
					key: 'selectedShop',
					render: (selectedShop) => selectedShop && this.fetchShop(selectedShop).map((selectedShop) => (selectedShop)).join(', '),
				},
				{
					title: 'Selected Location',
					dataIndex: 'selectedLocation',
					key: 'selectedLocation',
					render: (selectedLocation) => selectedLocation && selectedLocation.map((selectedLocations) => selectedLocations).join(),
				},
				{
					title: 'Employment Start Date',
					dataIndex: 'employmentStartDate',
					key: 'employmentStartDate',
					render: (employmentStartDate) => (employmentStartDate && moment(employmentStartDate).format('MMM DD, YYYY'))
				},
				{
					title: 'Last Evaluation Date',
					dataIndex: 'lastEvaluationDate',
					key: 'lastEvaluationDate',
					render: (lastEvaluationDate) => (lastEvaluationDate && moment(lastEvaluationDate).format('MMM DD, YYYY'))
				},
				{
					title: 'Group',
					dataIndex: 'group',
					key: 'group',
					render: (group) => group && Array.isArray(group) && group.map((groups) => groups).join(),
				},
				{
					title: 'Direct Manager',
					dataIndex: 'directManager',
					key: 'directManager'
				},
				{
					title: 'Title_',
					dataIndex: 'title_',
					key: 'title_'
				},
				{
					title: 'Term Date',
					dataIndex: 'termDate',
					key: 'termDate',
					render: (termDate) => (termDate && moment(termDate).format('MMM DD, YYYY'))
				},
				{
					title: 'Original Phone Number',
					dataIndex: 'originalPhoneNo',
					key: 'originalPhoneNo'
				},
				{
					title: 'Company Truck',
					dataIndex: 'companyTruck',
					key: 'companyTruck'
				},
				{
					title: 'Personal Truck',
					dataIndex: 'personalTruck',
					key: 'personalTruck'
				},
				{
					title: 'Los Housing',
					dataIndex: 'losHousing',
					key: 'losHousing',
					render: (_, record) => record.losHousing === true && 'YES'
				},
				{
					title: 'Mentor Program',
					dataIndex: 'mentorProgram',
					key: 'mentorProgram',
					render: (_, record) => record.mentorProgram === true && 'YES'
				},
				{
					title: 'Onboarding',
					dataIndex: 'onboarding',
					key: 'onboarding',
					render: (_, record) => record.onboarding === true && 'YES'
				}
			],
			filteredList: ['firstName', 'lastName', 'email', 'createdAt', 'employeeID', 'selectedShop', 'selectedLocation', 'employmentStartDate', 'group'],
			filteredCols: [],
			filtered: false,
			shopList: [
				{ key: 'admin', value: 'Admin-Receptionist' }, { key: 'asst_manager', value: 'Asst. Manager' }, { key: 'contractor', value: 'Contractor' },
				{ key: 'crane', value: 'Crane Operator' }, { key: 'dispatch', value: 'Dispatch' }, { key: 'field', value: 'Field' },
				{ key: 'forklift', value: 'Forklift (DOT)' }, { key: 'contract', value: 'HSE - Advisor (Contract)' }, { key: 'trainer', value: 'HSE - Advisor (Field Trainer)' },
				{ key: 'coordinator', value: 'HSE - Coordinator' }, { key: 'director', value: 'HSE - Director' }, { key: 'ofc_manager', value: 'OFC Manager' },
				{ key: 'office', value: 'Office' }, { key: 'shop', value: 'Shop' },
			]
		};
	}

	async componentDidUpdate (prevProps) {
		const { selectedTab, userColumns, filteredCols, filtered } = this.state;
		if (prevProps.auth.subscribeStart === false && this.props.auth.subscribeStart === true) {
			const response = await checkWritePermissions(this.props, 'users');
			const response1 = await checkDeletePermissions(this.props, 'users');
			this.setState({ isPermitted: response, isDeletePermitted: response1, Loader: true });
			setTimeout(() => {
				if (filtered === false && filteredCols.length === 0) {
					this.handleColsChanges(JSON.stringify(selectedTab), userColumns, false);
				} else {
					this.handleColsChanges(JSON.stringify(selectedTab), filteredCols, true);
				}
				this.setState({ Loader: false });
			}, 2000);
		}
	}

	componentDidMount () {
		const { filteredList } = this.state;

		this.setState({ Loader: true });
		this.getLOSEmployees();
		this.handleColsChanges('1', this.state.userColumns, false);
		if (filteredList) {
			this.handleFilterChange(filteredList);
		}
	}

	getLOSEmployees () {
		const { usersList } = this.props.auth;
		const archiveUsers = usersList.filter((item) => item.archive === true);
		const losUsers = usersList.filter((item) => item.archive !== true && item.userType === 'LOS');
		const ownerUsers = usersList.filter((item) => item.archive !== true && item.userType === 'owner');
		const adminUsers = usersList.filter((item) => item.archive !== true && item.userType === 'admin');
		const deletedUsers = usersList.filter((item) => item.archive !== true && item.deleteRequest === true);
		this.setState({ Loader: false });
		this.setState({ losEmployees: losUsers, admins: adminUsers, owners: ownerUsers, users: archiveUsers, deleteRequestUsers: deletedUsers });
	}

	fetchShop (selectedShop) {
		const { shopList } = this.state;
		if (!isEmpty(selectedShop)) {
			const shops = shopList.filter((item) => {
				if (selectedShop.indexOf(item.key) !== -1 || selectedShop.indexOf(item.value) !== -1) {
					return item.value;
				}
				return null;
			});
			return shops.map((key) => key.value);
		}
		return [];
	}

	handleUpdate (record) {
		if (record.userType === 'LOS') {
			this.props.history.push(`/users/los/edit/${record.$key}`, { los: true });
		} else {
			this.props.history.push(`/users/admin/edit/${record.$key}`, { los: true });
		}
	}

	async activateUser (record) {
		this.setState({ Loader: true });
		await sendEmail(record.email, 'Rigmove Account Activation', `<p style="font-size: 12px;">Your Login credentials are approved for email: ${record.email} </p>`);
		const db = firebase.firestore();
		await db.doc(`users/${record.$key}`).update({ verified: true })
			.then(() => this.getLOSEmployees());
		this.setState({ Loader: false });
	}

	async handleDelete (user) {
		user.archive = true;
		user.deleteRequest = false;
		const db = firebase.firestore();
		await db.collection('users').doc(user.$key).update(user);
		this.getLOSEmployees();
		await sendEmail(user.email, 'Account Deletion', 'Your account deleted successfully');
		alert('User is deleted successfully');
	}

	async handleUnarchive (user) {
		user.archive = false;
		user.deleteRequest = false;
		user.updatedAt = moment().valueOf();
		const db = firebase.firestore();
		await db.collection('users').doc(user.$key).update(user);
		this.getLOSEmployees();
		setTimeout(() => {
			alert('User is unarchived successfully');
		}, 100);
	}

	onChange = (key) => {
		const { userColumns, filteredCols, filtered } = this.state;
		this.setState({ selectedTab: parseInt(key), searchInput: '' });
		if (filtered === false && filteredCols.length === 0) {
			this.handleColsChanges(key, userColumns, false);
		} else {
			this.handleColsChanges(key, filteredCols, true);
		}
	};

	userHeader = () => {
		const { isAdmin, filtered, selectedTab, searchInput, isPermitted } = this.state;
		return (
			<>
				<Row className="search-rig-header">
					<Col className="search-btn-part" span={4}>
						<div className="filter-table-area">
							<span className={`filter-btn ${filtered ? 'active' : ''}`} onClick={() => this.onFilterClick()}>
								<BsLayoutThreeColumns />
							</span>
							{ filtered && <div className="filter-column-box">{this.columnLists()}</div> }
						</div>
					</Col>
					<Col className="search-btn-part" span={20}>
						<Search
							placeholder="Search..."
							onSearch={value => this.rigSearch(value)}
							style={{ width: '100%' }}
							className="rigSearch"
							allowClear={true}
							onChange={data => this.rigSearch(data.target.value)}
							value={searchInput}
						/>
						<button className="site-btn export-btn">
							Export
						</button>
						{isAdmin === true && isPermitted && (selectedTab === 1 || selectedTab === 2) &&
							<button className="site-btn addusers-btn" onClick={() => selectedTab === 1 ? this.props.history.push('/users/los/add') : this.props.history.push('/users/admin/add')}>
								<IoIosAddCircleOutline />
								<span>Add Users</span>
							</button>
						}
					</Col>
				</Row>
			</>
		);
	}

	handleColsChanges (type, definedCols, filter) {
		const { isAdmin, isPermitted, isDeletePermitted, isOwner } = this.state;

		if (isAdmin === true && type === '1') {
			let flag1 = false, index1 = 0;
			definedCols.forEach((col) => {
				if (col.key.includes('activate') === false) {
					index1 += 1;
				}
				if (index1 === definedCols.length) {
					flag1 = true;
				}
				if (flag1 === true && isPermitted) {
					definedCols.push({
						title: 'Activate',
						dataIndex: 'Activate',
						key: 'activate',
						render: (_, record) => (
							<Popconfirm disabled={record.verified} title="Are you sure to activate this user?" onConfirm={() => this.activateUser(record)}>
								<a href="true" style={record.verified ? { color: 'grey', cursor: 'default' } : { color: '#1890ff' }}>Activate</a>
							</Popconfirm>
						)
					});
				}
				if (!flag1 && !isPermitted) {
					this.userColumnDelete('activate', definedCols);
				}
			});
		} else {
			this.userColumnDelete('activate', definedCols);
		}

		if ((isAdmin === true && type === '1') || (isOwner === true && (type === '2' || type === '0'))) {
			let flag1 = false, index1 = 0;
			definedCols.forEach((col) => {
				if (col.key.includes('password') === false) {
					index1 += 1;
				}
				if (index1 === definedCols.length) {
					flag1 = true;
				}
				if (flag1 === true && isPermitted) {
					definedCols.push({
						title: 'Password',
						dataIndex: 'Password',
						key: 'password',
						render: (_, record) => (
							<p className="table-action-link box-link" onClick={() => this.openModal(record)}>
								Change
							</p>
						)
					});
				}
				if (!flag1 && !isPermitted) {
					this.userColumnDelete('password', definedCols);
				}
			});
		} else {
			this.userColumnDelete('password', definedCols);
		}

		if (isAdmin === true && type === '3') {
			definedCols.forEach((col) => {
				if (col.key.includes('edit') === true) {
					this.userColumnDelete('edit', definedCols);
				}
			});
			definedCols.forEach((col) => {
				if (col.key.includes('deleteRequest') === true) {
					this.userColumnDelete('deleteRequest', definedCols);
				}
			});
			definedCols.forEach((col) => {
				if (col.key.includes('delete') === true) {
					this.userColumnDelete('delete', definedCols);
				}
			});

			let flag1 = false, index1 = 0;
			definedCols.forEach((col) => {
				if (col.key.includes('unarchive') === false) {
					index1 += 1;
				}
				if (index1 === definedCols.length) {
					flag1 = true;
				}
				if (flag1 === true && isDeletePermitted) {
					definedCols.push({
						title: 'Unarchive',
						dataIndex: 'Unarchive',
						key: 'unarchive',
						render: (_, record) => (
							<Popconfirm overlayClassName="custom-popconfirm" title="Are you sure to unarchive this user permanently!!?" onConfirm={() => this.handleUnarchive(record)}>
								<a href="true">Unarchive</a>
							</Popconfirm>
						)
					});
				}
				if (!flag1 && !isDeletePermitted) {
					this.userColumnDelete('unarchive', definedCols);
				}
			});
		} else {
			this.userColumnDelete('unarchive', definedCols);
		}

		if (isAdmin === true && (type === '1' || type === '2')) {
			definedCols.forEach((col) => {
				if (col.key.includes('deleteRequest') === true) {
					this.userColumnDelete('deleteRequest', definedCols);
				}
			});

			let flag = false, index = 0, flag1 = false, index1 = 0;
			if (filter === false) {
				this.setState({ userColumns: definedCols });
			} else {
				this.setState({ filteredCols: definedCols });
			}
			definedCols.forEach((col) => {
				if (col.key.includes('delete') === false) {
					index += 1;
				}
				if (index === definedCols.length) {
					flag = true;
				}
				if (col.key.includes('edit') === false) {
					index1 += 1;
				}
				if (index1 === definedCols.length) {
					flag1 = true;
				}
				if (flag === true && isDeletePermitted === true) {
					definedCols.push({
						title: 'Delete',
						dataIndex: 'Delete',
						key: 'delete',
						render: (_, record) => (
							<Popconfirm overlayClassName="custom-popconfirm" title="Are you sure to delete this user permanently!!?" onConfirm={() => this.handleDelete(record)}>
								<a href="true">Delete</a>
							</Popconfirm>
						)
					});
				}
				if (flag1 === true && isPermitted === true) {
					definedCols.push({
						title: 'Edit',
						dataIndex: 'Edit',
						key: 'edit',
						render: (_, record) => (
							<Popconfirm overlayClassName="custom-popconfirm" title="Are you sure to update this user?" onConfirm={() => this.handleUpdate(record)}>
								<a href="true">Edit</a>
							</Popconfirm>
						)
					});
				}
				if (!flag && !isDeletePermitted) {
					this.userColumnDelete('delete', definedCols);
				}
				if (!flag1 && !isPermitted) {
					this.userColumnDelete('edit', definedCols);
				}
			});
		}

		if (isAdmin === true && type === '4') {
			definedCols.forEach((col) => {
				if (col.key.includes('edit') === true) {
					this.userColumnDelete('edit', definedCols);
				}
			});
			definedCols.forEach((col) => {
				if (col.key.includes('delete') === true) {
					this.userColumnDelete('delete', definedCols);
				}
			});
			let flag = false, index = 0;
			definedCols.forEach((col) => {
				if (col.key.includes('deleteRequest') === false) {
					index += 1;
				}
				if (index === definedCols.length) {
					flag = true;
				}
				if (flag === true && isDeletePermitted) {
					definedCols.push({
						title: 'Delete Request',
						dataIndex: 'deleteRequest',
						key: 'deleteRequest',
						render: (_, record) => (
							record.archive === true ?
								<span href='' style={{ color: '#acacac' }}>Accept</span>
								:
								<Popconfirm overlayClassName="custom-popconfirm" title="Are you sure to delete this user permanently!!?" onConfirm={() => this.handleDelete(record)}>
									<a href="true">Accept</a>
								</Popconfirm>
						)
					});
				}
				if (!flag && !isDeletePermitted) {
					this.userColumnDelete('deleteRequest', definedCols);
				}
			});
			if (filter === false) {
				this.setState({ userColumns: definedCols });
			} else {
				this.setState({ filteredCols: definedCols });
			}
		}
	}

	userColumnDelete (key, definedCols) {
		definedCols.forEach((col, index) => {
			if (col.key.includes(key) === true) {
				definedCols.splice(index, 1);
			}
		});
	}

	filterData = (item) => {
		const string = this.state.searchInput.toLowerCase();
		return item.firstName.toLowerCase().match(string) || item.lastName.toLowerCase().match(string) || item.email.toLowerCase().match(string);
	};

	userLists (users) {
		const { filtered, selectedTab } = this.state;
		let { userColumns, filteredCols } = this.state;

		if (selectedTab === 2) {
			if (filtered === false) {
				userColumns = userColumns.filter(col => col.dataIndex !== 'countryCode');
				userColumns = userColumns.filter(col => col.dataIndex !== 'onboarding');
				filteredCols = filteredCols.filter(col => col.dataIndex !== 'countryCode');
				filteredCols = filteredCols.filter(col => col.dataIndex !== 'onboarding');
			}
		}
		return (
			<div style={{ marginTop: 20 }}>
				<Row>
					<Col span={24}>
						<Table columns={filteredCols.length === 0 ? userColumns : filteredCols} dataSource={users.filter(this.filterData)} rowKey={record => record.$key} scroll={{ x: true }} />
					</Col>
				</Row>
			</div>
		);
	}

	columnLists = () => {
		const { filteredList, selectedTab } = this.state;
		return (
			<Menu selectedKeys={filteredList} multiple={true} onClick={(value) => this.handleMenuChange(value)}>
				<Menu.Item key="firstName" onClick={(value) => this.setState({ 'firstName': value.key })}>firstName</Menu.Item>
				<Menu.Item key="lastName" onClick={(value) => this.setState({ 'lastName': value.key })}>lastName</Menu.Item>
				<Menu.Item key="email" onClick={(value) => this.setState({ 'email': value.key })}>email</Menu.Item>
				<Menu.Item key="phoneNumber" onClick={(value) => this.setState({ 'phoneNumber': value.key })}>phoneNumber</Menu.Item>
				<Menu.Item key="userType" onClick={(value) => this.setState({ 'userType': value.key })}>userType</Menu.Item>
				<Menu.Item key="createdAt" onClick={(value) => this.setState({ 'createdAt': value.key })}>createdAt</Menu.Item>
				<Menu.Item key="updatedAt" onClick={(value) => this.setState({ 'updatedAt': value.key })}>updatedAt</Menu.Item>
				<Menu.Item key="verified" onClick={(value) => this.setState({ 'verified': value.key })}>verified</Menu.Item>
				{selectedTab !== 2 &&
					<Menu.Item key="countryCode" onClick={(value) => this.setState({ 'countryCode': value.key })}>countryCode</Menu.Item>
				}
				<Menu.Item key="employeeID" onClick={(value) => this.setState({ 'employeeID': value.key })}>employeeID</Menu.Item>
				<Menu.Item key="selectedShop" onClick={(value) => this.setState({ 'selectedShop': value.key })}>Shop/Office/Field</Menu.Item>
				<Menu.Item key="selectedLocation" onClick={(value) => this.setState({ 'selectedLocation': value.key })}>selectedLocation</Menu.Item>
				<Menu.Item key="employmentStartDate" onClick={(value) => this.setState({ 'employmentStartDate': value.key })}>employmentStartDate</Menu.Item>
				<Menu.Item key="lastEvaluationDate" onClick={(value) => this.setState({ 'lastEvaluationDate': value.key })}>lastEvaluationDate</Menu.Item>
				<Menu.Item key="group" onClick={(value) => this.setState({ 'group': value.key })}>group</Menu.Item>
				<Menu.Item key="directManager" onClick={(value) => this.setState({ 'directManager': value.key })}>directManager</Menu.Item>
				<Menu.Item key="title_" onClick={(value) => this.setState({ 'title_': value.key })}>title_</Menu.Item>
				<Menu.Item key="termDate" onClick={(value) => this.setState({ 'termDate': value.key })}>termDate</Menu.Item>
				<Menu.Item key="originalPhoneNo" onClick={(value) => this.setState({ 'originalPhoneNo': value.key })}>originalPhoneNo</Menu.Item>
				<Menu.Item key="companyTruck" onClick={(value) => this.setState({ 'companyTruck': value.key })}>companyTruck</Menu.Item>
				<Menu.Item key="personalTruck" onClick={(value) => this.setState({ 'personalTruck': value.key })}>personalTruck</Menu.Item>
				<Menu.Item key="losHousing" onClick={(value) => this.setState({ 'losHousing': value.key })}>losHousing</Menu.Item>
				<Menu.Item key="mentorProgram" onClick={(value) => this.setState({ 'mentorProgram': value.key })}>mentorProgram</Menu.Item>
				{selectedTab !== 2 &&
					<Menu.Item key="onboarding" onClick={(value) => this.setState({ 'onboarding': value.key })}>onboarding</Menu.Item>
				}
			</Menu>
		);
	}

	onFilterClick = () => {
		const { filtered, filteredList } = this.state;
		this.setState({ filtered: !filtered });
		if (filtered === true) {
			this.handleFilterChange(filteredList);
		}
	}

	handleMenuChange = (value) => {
		const { filteredList } = this.state;
		if (filteredList.includes(value.key) === true) {
			if (filteredList.length > 3) {
				const index = filteredList.indexOf(value.key);
				filteredList.splice(index, 1);
			} else {
				alert('Atleast 3 columns will remain selected for table');
			}
		} else {
			filteredList.push(value.key);
		}
		this.setState({ filteredList });
		this.handleFilterChange(filteredList);
	}

	handleFilterChange = (value) => {
		const { userColumns, selectedTab } = this.state;
		const userCols = [];
		userColumns.forEach((col) => {
			value.forEach((item) => {
				if (col.key === item) {
					userCols.push(col);
				}
			});
		});
		this.setState({ filteredCols: userCols });
		this.handleColsChanges(JSON.stringify(selectedTab), userCols, true);
	}

	openModal = (record) => {
		this.setState({ showModal: true, selectedUID: record.uid });
	}

	handleOk = (e) => {
		e.preventDefault();
		const { selectedUID } = this.state;
		const { form } = this.props;
		form.validateFields(async (err, values) => {
			this.setState({ validateTrigger: true });
			if (!err) {
				this.setState({ showModal: false });
				this.setState({ loader: true });

				changePassword(selectedUID, values.password)
					.then(() => {
						this.setState({ loader: false });
						form.resetFields();
						alert('Password updated successfully!');
					}).catch((error) => {
						this.setState({ loader: false });
						form.resetFields();
						if (error.response && error.response.data) {
							alert(error.response.data.message);
						} else {
							alert('Something went wrong!');
						}
					});
			}
		});
	};

	handleCancel = () => {
		this.setState({ showModal: false });
	};

	rigSearch (value) {
		this.setState({ searchInput: value });
	}

	render () {
		const { Loader, losEmployees, admins, owners, users, selectedTab, showModal, validateTrigger, deleteRequestUsers, isAdmin, isOwner } = this.state;
		const { form: { getFieldDecorator } } = this.props;
		if (Loader === true) {
			return (
				<div className="loader">
					<img src={loader} alt="loader" />
				</div>
			);
		}
		return (
			<div className="card-tab-body">
				<div className="custom-tabs no-mt">
					<Tabs type="card" activeKey={JSON.stringify(selectedTab)} onChange={this.onChange} centered>
						<TabPane tab={`Mobile Users (${losEmployees.length})`} key="1">
							{this.userHeader()}
							{selectedTab === 1 && this.userLists(losEmployees)}
						</TabPane>
						<TabPane tab={`Admins (${admins.length})`} key="2">
							{this.userHeader()}
							{selectedTab === 2 && this.userLists(admins)}
						</TabPane>
						{isOwner === true &&
							<TabPane tab={`Owners (${owners.length})`} key="0">
								{this.userHeader()}
								{selectedTab === 0 && this.userLists(owners)}
							</TabPane>
						}
						<TabPane tab={`Archived (${users.length})`} key="3">
							{this.userHeader()}
							{selectedTab === 3 && this.userLists(users)}
						</TabPane>
						{isAdmin === true &&
							<TabPane tab={`Delete Request from User (${deleteRequestUsers.length})`} key="4">
								{this.userHeader()}
								{selectedTab === 4 && this.userLists(deleteRequestUsers)}
							</TabPane>
						}
					</Tabs>
				</div>
				<Modal className="send-mail-modal" title="Change Password" visible={showModal} onOk={this.handleOk} onCancel={this.handleCancel}>
					<Form layout="vertical" onSubmit={(e) => this.handleSubmit(e)} className="theme-form">
						<Form.Item label="Password">
							{getFieldDecorator('password', {
								validateTrigger: validateTrigger ? 'onChange' : '',
								rules: [
									{ required: true, message: 'Please enter your password' },
									{ min: 6, message: 'Enter valid password more than 6 characters' }
								],
								initialValue: ''
							})(
								<Input
									type="password"
									autoComplete="new-password"
								/>,
							)}
						</Form.Item>
					</Form>
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	auth: state.authReducer
});

export default connect(mapStateToProps)(withRouter(Form.create()(Users)));